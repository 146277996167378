import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Paper, Box, Tooltip, Typography, Grid, TablePagination, Collapse, IconButton } from "@mui/material";
import TableFooter from "@mui/material/TableFooter";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import LastPageIcon from "@mui/icons-material/LastPage";

import { DataNotFound, Loader } from "./index";
import theme, { textClasses } from "./theme";
import { getShortText } from "../../services/functions";

export default function TableMui({
	header,
	rows,
	TableData,
	pageCount,
	pageNumber,
	handleChangePage,
	handleChangeRowsPerPage,
	rowsPerPage,
	isLoading,
	openRows,
	openRowsData = {
		header: [],
		rows: [],
	},
	IsScroll = false,
}) {
	const renderData = (column_data) => {
		if (column_data && ["number", "string"].includes(typeof column_data)) {
			if ((typeof column_data == "number" && column_data + "".length < 21) || column_data.length < 21) {
				return column_data;
			} else {
				if (typeof column_data == "object" && column_data.props && typeof column_data.props == "object") {
					column_data = {
						...column_data,
						props: {
							...column_data.props,
							sx: column_data?.props?.sx || {
								...textClasses.t13n,
							},
						},
					};
				} else if (typeof column_data == "object") {
					column_data = {
						...column_data,
						sx: column_data?.sx || { ...textClasses.t13n },
					};
				}
				return (
					<Tooltip title={column_data} placement="top">
						<Typography {...column_data.props} sx={{ ...textClasses.t13n }}>
							{getShortText(column_data, 21)}
						</Typography>
					</Tooltip>
				);
			}
		} else if (column_data?.props?.children && ["number", "string"].includes(typeof column_data.props.children)) {
			if ((typeof column_data.props.children == "number" && column_data.props.children + "".length < 21) || column_data.props.children.length < 21) {
				return column_data;
			} else {
				if (typeof column_data == "object" && column_data.props && typeof column_data.props == "object") {
					column_data = {
						...column_data,
						props: {
							...column_data.props,
							sx: column_data?.props?.sx || {
								...textClasses.t13n,
							},
						},
					};
				} else if (typeof column_data == "object") {
					column_data = {
						...column_data,
						sx: column_data?.sx || { ...textClasses.t13n },
					};
				}

				return (
					<Tooltip title={column_data} placement="top">
						<Typography {...column_data.props}>{getShortText(column_data.props.children, 21)}</Typography>
					</Tooltip>
				);
			}
		} else {
			return column_data;
		}
	};

	useEffect(() => {
		const el = document.getElementById("table-wrapper");
		if (!el) return;
		// scrollWidth gives the total width of the inside content,
		// including what is not visible on the screen due to overflow.
		// clientWidth gives the visible width of the element.
		// Setting scrollLeft to scrollWidth - clientWidth scrolls to the end.
		if (IsScroll) {
			setTimeout(() => {
				el.scrollLeft = el.scrollWidth - el.clientWidth;
			}, 600);
		}
	});
	// console.log({
	// header,
	// rows,
	// pageCount,
	// pageNumber,
	// handleChangePage,
	// handleChangeRowsPerPage,
	// rowsPerPage,
	// });
	return (
		<Paper
			id="table-wrapper"
			sx={{
				backgroundColor: "var(--background-color)",
				width: "100%",
				height: "100%",
				overflow: "auto",
				scrollBehavior: "smooth",
			}}
		>
			<Box className="muitable" style={{ width: "100%", height: "100%" }}>
				<table style={{ width: "100%", height: "100%" }}>
					<thead style={{ backgroundColor: "var(--table-header-bg-color)" }}>
						<tr className="roundedRow">
							{header.map((head, i) => {
								return (
									//padding: "13px 10px 13px 10px",
									<td
										key={i}
										style={{
											...textClasses.boldText,
											...textClasses.td,
											textAlign: "center",
											color: "var(--table-header-text-color)",
										}}
									>
										{head}
									</td>
								);
							})}
						</tr>
					</thead>
					<tbody style={{ backgroundColor: "var(--table-body-bg-color)", color: "var(--text-color)" }}>
						{isLoading ? (
							<tr>
								<td colSpan={100}>
									<Loader />
								</td>
							</tr>
						) : rows?.length > 0 ? (
							<>
								{rows.map((row, i) => {
									return (
										<React.Fragment key={i}>
											<tr
												key={i}
												style={{
													borderBottom: "1px solid #0000002B",
													backgroundColor: row.bgColor ?? "",
												}}
											>
												{row.map((col, j) => {
													return (
														<td
															key={i + "/" + j}
															style={{
																...textClasses.t13n,
																...textClasses.td,
																textAlign: "center",
																width: j == row.length - 1 ? "6rem" : "auto",
															}}
														>
															{renderData(col)}
														</td>
													);
												})}
											</tr>
											<tr>
												<td colSpan={header.length + 1} style={{ padding: 0 }}>
													<Collapse in={openRows === i} timeout="auto" unmountOnExit>
														<Box margin={1} sx={{ border: "1px solid #0000002B", borderBottom: "none", borderRadius: "9px", padding: "0px", backgroundColor: "white" }}>
															<table width="100%" style={{ borderCollapse: "separate", borderSpacing: "0", borderRadius: "8px", overflow: "hidden" }}>
																<thead style={{ backgroundColor: "var(--table-inner-header-bg-color)" }}>
																	<tr className="roundedRow">
																		{openRowsData.header?.map((head, i) => {
																			return (
																				<td
																					key={i}
																					style={{
																						...textClasses.boldText,
																						...textClasses.td,
																						textAlign: "center",
																						color: "var(--table-header-text-color)",
																					}}
																				>
																					{head}
																				</td>
																			);
																		})}
																	</tr>
																</thead>
																<tbody style={{ backgroundColor: "var(--table-body-bg-color)", color: "var(--text-color)" }}>
																	{openRowsData.rows?.length > 0 ? (
																		openRowsData.rows?.map((row, i) => (
																			<tr
																				key={i}
																				style={{
																					...textClasses.t13n,
																					...textClasses.td,
																					textAlign: "center",
																					backgroundColor: TableData && TableData[i + openRowsData.startRow]?.bgColor ? "#e6ffe6" : "",
																				}}
																			>
																				{row.map((col, j) => (
																					<td
																						key={i + "/" + j}
																						style={{
																							...textClasses.t13n,
																							...textClasses.td,
																							textAlign: "center",
																							backgroundColor: TableData && TableData[i + openRowsData.startRow]?.bgColor ? "#e6ffe6" : "",
																							width: j == row.length - 1 ? "6rem" : "auto",
																							borderBottom: "1px solid #0000002B",
																						}}
																					>
																						{renderData(col)}
																					</td>
																				))}
																			</tr>
																		))
																	) : (
																		<tr
																			sx={{
																				"&:last-child td, &:last-child th": {
																					border: 0,
																				},
																			}}
																		>
																			<td
																				component="th"
																				scope="row"
																				colSpan={11}
																				style={{
																					...textClasses.normalText,
																					textAlign: "center",
																					overflowWrap: "break-word",
																				}}
																			>
																				<Grid container justifyContent={"center"} alignItems="center" sx={{ mb: 2 }}>
																					<DataNotFound />
																				</Grid>
																			</td>
																		</tr>
																	)}
																</tbody>
															</table>
														</Box>
													</Collapse>
												</td>
											</tr>
										</React.Fragment>
									);
								})}
							</>
						) : (
							<tr
								sx={{
									"&:last-child td, &:last-child th": {
										border: 0,
									},
								}}
							>
								<td
									component="th"
									scope="row"
									colSpan={11}
									style={{
										...textClasses.normalText,
										textAlign: "center",
										overflowWrap: "break-word",
									}}
								>
									<Grid container justifyContent={"center"} alignItems="center" sx={{ mb: 2 }}>
										<DataNotFound />
									</Grid>
								</td>
							</tr>
						)}
						<tr className="p-0">
							<td className="p-0" component="th" scope="row" colSpan={100}>
								{pageCount > 0 && rows?.length > 0 && (
									<TablePagination
										component="div"
										count={pageCount}
										page={pageNumber - 1}
										rowsPerPage={rowsPerPage}
										rowsPerPageOptions={pageCount > 10 ? [10, 25, 50, 100] : [10]}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
										ActionsComponent={TablePaginationActions}
										classes={{
											paper: {
												backgroundColor: "#212529",
											},
										}}
										sx={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											color: "var(--text-color)",
											backgroundColor: "var(--table-pagination-bg)",
											// mt: "0.5em",
											"& .MuiTablePagination-selectLabel ": {
												margin: "0",
											},
											"& .MuiTablePagination-displayedRows": {
												margin: "0",
											},

											"& .MuiTablePagination-selectIcon": {
												color: "var(--text-color)",
											},
										}}
									/>
								)}
							</td>
						</tr>
					</tbody>
				</table>
			</Box>
		</Paper>
	);
}

export function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = () => {
		onPageChange(0);
	};

	const handleBackButtonClick = () => {
		onPageChange(page - 1);
	};

	const handleNextButtonClick = () => {
		onPageChange(page + 1);
	};

	const handleLastPageButtonClick = () => {
		onPageChange(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	// console.log({ count, page });
	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton style={{ color: "var(--text-color)" }} onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton style={{ color: "var(--text-color)" }} onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
				{theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton style={{ color: "var(--text-color)" }} onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
				{theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton style={{ color: "var(--text-color)" }} onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};
