import { createPortal } from "react-dom";
import { Loader } from "../utils/index";
import { useEffect, useState } from "react";
import { useUpdateTourStatusMutation } from "../../api/userSlice";
import { useUserDetail } from "../../services/hooks";
import { useSidebarItems, useTour } from "../../services/context";

function GuidedTour() {
	const sidebarItems = useSidebarItems();

	const tourItems = [
		{
			title: "Welcome to LynkID",
			description: "This tour will familiarize you with some of the core modules. Click the next button to get started",
		},
		...sidebarItems?.sidebarItems,
	];
	const [currentTourItem, setCurrentTourItem] = useState(0);
	const [updateTour, { isLoading: isUpdating, data }] = useUpdateTourStatusMutation();
	const { userDetail } = useUserDetail();
	const [, setReceivedGuidedTour] = useTour();
	
	const handleTourPrev = () => {
		if (currentTourItem == 0) return;
		setCurrentTourItem((i) => i - 1);
	};

	const handleTourNext = () => {
		const isCheckpointGroup = tourItems[currentTourItem].class?.includes("checkpoint") && tourItems[currentTourItem + 1].class?.includes("checkpoint");
		const isLastTourItemn = currentTourItem == tourItems.length - 1;

		if (isLastTourItemn || isCheckpointGroup) {
			handleTourFinish();
		} else {
			setCurrentTourItem((i) => i + 1);
		}
	};
	const handleTourFinish = () => {
		updateTour({ receivedGuidedTour: true, userId: userDetail?.userId })
			.unwrap()
			.then((res) => {
				if (!res.error) {
					setReceivedGuidedTour(true);
				}
			});
	};
	useEffect(() => {
		let tourBox = document.querySelector(".tour-box");
		let sidebar = document.querySelector(".layout aside");
		let links = document.querySelectorAll(".navlink");
		let titles = document.querySelectorAll(".layout aside .sidebar_title");
		let targetElems = [];
		if (!tourBox) return;
		if (currentTourItem == 0) {
			tourBox.style.margin = "auto";
			tourBox.style.top = "0";
			tourBox.style.left = "0";
			tourBox.style.right = "0";
			tourBox.style.bottom = "0";

			if (sidebar) {
				sidebar.classList.remove("open");
			}
		} else {
			if (sidebar) {
				sidebar.classList.add("open");
				titles.forEach((title) => {
					title.style.display = "block";
				});
				links.forEach((link) => {
					link.classList.remove("active");
				});
			}

			let targets = document.querySelectorAll(`.${tourItems[currentTourItem].class}`);

			targetElems = [];
			targets.forEach((target) => {
				targetElems.push(target.parentElement);
			});

			if (targetElems.length) {
				targetElems.forEach((targetElem) => {
					targetElem.style.backgroundColor = "var(--primary-color)";
					targetElem.style.zIndex = 2;
					targetElem.style.position = "relative";
					targetElem.style.boxShadow = "2px 2px 6px #777";
				});
				let rect = targetElems[0].getBoundingClientRect();
				tourBox.style.margin = "0";
				tourBox.style.top = `${rect.top}px`;
				tourBox.style.left = `208px`;

				let boxRect = tourBox.getBoundingClientRect();
				// the tour box will cause scroll
				if (boxRect.bottom > window.innerHeight) {
					tourBox.style.top = `${rect.top - (boxRect.bottom - window.innerHeight)}px`;
				}
			}
		}
		return () => {
			if (sidebar) {
				sidebar.classList.remove("open");
			}
			if (titles) {
				titles.forEach((title) => {
					title.style.display = "";
				});
			}

			if (targetElems?.length) {
				targetElems.forEach((targetElem) => {
					targetElem.style.boxShadow = "none";
					targetElem.style.position = "static";
					targetElem.style.zIndex = 0;
					targetElem.style.backgroundColor = "transparent";
					targetElem.style.opacity = "100%";
				});
			}
		};
	}, [currentTourItem]);
	return createPortal(
		<div id="guided-tour">
			{isUpdating ? (
				<Loader size="2rem" height="50rem" />
			) : data?.error == false ? null : (
				<div className="tour-box">
					<div className="tour-body">
						<div className="tour-title">{tourItems[currentTourItem].title}</div>
						<div className="tour-content">{tourItems[currentTourItem].description}</div>
					</div>
					<div className="tour-footer">
						<button className="skip" onClick={handleTourFinish} disabled={isUpdating}>
							Skip
						</button>

						<div className="nav-btns">
							<button className="prev" onClick={handleTourPrev} disabled={isUpdating}>
								Prev
							</button>
							<button className="next" onClick={handleTourNext} disabled={isUpdating}>
								{currentTourItem == tourItems.length - 1 ? "Finish Tour" : "Next"}
							</button>
						</div>
					</div>
				</div>
			)}
		</div>,
		document.body
	);
}

export default GuidedTour;
