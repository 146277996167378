export const config = {
	footerText: "Copyright LynkID © 2025",
	// socketURL: "http://localhost:9008/",
	// socketURL : "https://warehouse-new.lynkgrid.com/",
	socketURL: "/",
	baseUrl: import.meta.env.VITE_BASE_URL,
	// baseUrl: "http://localhost:8980/api/",
	// baseUrl: "https://web.lynkid.io/api/",
	// baseUrl: "https://preweb.lynkid.io/api/",
	// baseUrl: "https://www.lynkid.io/api",
};
