import React, { useState, useRef, useEffect } from 'react';
import { Grid } from "@mui/material";
import './ApprovalConfig.css';
import { Dropdown, Input, Button, ActionMenu, GenericTable, tCell, Checkbox, AddRemove } from "../utils";
import { buttonClasses } from "../utils/theme";
import { toastMessage } from "../utils";
import Genericfilter from "../utils/Genericfilter";
import { debounce } from "../../services/functions";
import { useOrgs, useRoles, useUsers, useUserDetail, useUserPermissions } from '../../services/hooks';
import { useGetApprovalConfiggQuery, useAddApprovalConfigMutation, useUpdateApprovalConfigMutation, useDeleteApprovalConfigMutation } from '../../api/vmsSlice';
import { useGetTemplateListQuery } from '../../api/vmsSlice';

const initialState = {
    orgId: "",
    orgName: "",
    module: "",
    last_step_sequence: "",
    templateId: "",
    rejectTemplateId:"",
    approval_layer: [{
        layerName: "",
        order_no: "",
        role: "",
        role_id: "",
        require_user_approval: true,
        approval_require: false,
        user_details: {
            name: "",
            email: "",
            id: ""
        },
    }]
}

export default function ApprovalConfigIndex() {
    //state
    const userFilterRef = useRef({});
    const templateRef = useRef({});
    const { userDetail } = useUserDetail();
    const [openRows, setOpenRows] = useState("");
    const [openRowsData, setOpenRowsData] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [formData, setFormData] = useState({ ...initialState });
    const [error, setError] = useState([]);
    const [filter, setFilter] = useState({
        pagesize: 10,
        page_no: 1,
    });

    //api calls
    const { data: templateList, refetch: refetchTemplate } = useGetTemplateListQuery(templateRef.current, { refetchOnMountOrArgChange: true });
    const { data: approvalListData = {}, isLoading: listingLoading } = useGetApprovalConfiggQuery(filter, { refetchOnMountOrArgChange: true });
    const { data: approvalListing, count } = approvalListData;
    const [addApprovalConfigTriggier] = useAddApprovalConfigMutation();
    const [updateApprovalConfigTriggier] = useUpdateApprovalConfigMutation();
    const [deleteApprovalConfigTriggier] = useDeleteApprovalConfigMutation();

    //hooks
    const { users, count: userCount, isLoading, getUsers } = useUsers(userFilterRef.current);
    const userOptions = users.map((user) => ({ label: user.name, value: user._id, email: user.email })) || [];
    const { orgOptions } = useOrgs();
    const { roleOptions, getRoles, isLoading: roleLoading } = useRoles(userFilterRef.current);


    const filterFields = [
        {
            inputType: "textfield",
            id: "1",
            name: "module",
            type: "text",
            label: "Module",
            placeholder: "Module",
            readOnly: false,
            error: "",
            value: "",
        },
    ];
    if (userDetail?.userType == "superAdmin") {
        filterFields.push({
            inputType: "dropdown",
            id: "orgId",
            name: "orgId",
            label: "Organization",
            error: "",
            option: orgOptions,
        });
    }

    const handleChange = (value, name, index) => {
        //  console.log(value, name, index);
        if (index === undefined) {
            setFormData(prevState => {
                const updatedData = prevState;
                if (name === 'orgId') {
                    updatedData.orgId = value?.value || "";
                    updatedData.orgName = value?.label || "";
                    userFilterRef.current = {
                        orgId: value?.value || "",
                    }
                    getRoles(userFilterRef.current);
                    getUsers(userFilterRef.current);
                    templateRef.current = { organization_id: value.value };
                    refetchTemplate(templateRef.current);
                }
                if (name === 'module') {
                    updatedData.module = value || "";
                }
                if (name === 'last_step_sequence') {
                    updatedData.last_step_sequence = Number(value) || "";
                }
                if (name === 'templateId') {
                    updatedData.templateId = value || "";
                }
                if (name === 'rejectTemplateId'){
                    updatedData.rejectTemplateId= value || "";
                }
                return {
                    ...prevState,
                    ...updatedData
                }
            })

        } else {
            setFormData(prevState => {
                const updatedData = prevState.approval_layer;
                if (name === "user_details") {
                    if (value === null) {
                        updatedData[index][name] = {
                            name: "",
                            email: "",
                            id: ""
                        }
                    } else {
                        updatedData[index][name] = {
                            name: value.label,
                            email: value.email,
                            id: value.value
                        }
                    }
                } else if (name === 'role') {
                    // console.log(value, name, index);
                    if (value === null) {
                        updatedData[index]['role_id'] = "";
                        updatedData[index]['role'] = "";
                    } else {
                        updatedData[index]['role_id'] = value.data?._id;
                        updatedData[index]['role'] = value.value;
                    }
                } else if (name === 'order_no') {
                    updatedData[index][name] = Number(value);
                } else {
                    updatedData[index][name] = value;
                }
                return {
                    ...prevState,
                    approval_layer: updatedData
                }
            })
        }
    }

    const addApprovalLayer = () => {
        setFormData(prevState => {
            const updatedData = prevState.approval_layer;
            updatedData.push({
                layerName: "",
                order_no: "",
                role: "",
                role_id: "",
                require_user_approval: true,
                user_details: {
                    name: "",
                    email: "",
                    id: ""
                },
            })
            return {
                ...prevState,
                approval_layer: updatedData
            }
        })
    }

    const removeApprovalLayer = (index) => {
        setFormData(prevState => {
            const updatedData = prevState.approval_layer;
            updatedData.splice(index, 1);
            return {
                ...prevState,
                approval_layer: updatedData
            }
        })
    }

    const handleFilterChange = (filterObj) => {
        const updatedFilter = { ...filter };
        const orgIdValue = filterObj?.orgId?.value || "";
        const moduleValue = filterObj?.module || "";
        updatedFilter.orgId = orgIdValue;
        updatedFilter.module = moduleValue;
        setFilter(updatedFilter);
    };

    const handleEdit = () => {
        setIsEdit(!isEdit);
        setIsUpdating(false);
        setFormData(JSON.parse(JSON.stringify(initialState)));
    }

    const handleSubmit = () => {
        let updatedFormData = { ...formData };
        if (userDetail?.userType !== "superAdmin") {
            updatedFormData = {
                ...formData,
                orgId: userDetail?.orgId || "",
                orgName: userDetail?.orgName || ""
            };
        }
        // console.log(" =================== payload \n ", updatedFormData);
        const validation = validatePayload(updatedFormData);
        if (!validation.success) {
            // console.log(validation);
            setError(validation.error);
        } else {
            // console.log(formData);
            addApprovalConfigTriggier(updatedFormData).unwrap()
                .then((res) => {
                    if (!res.error) {
                        toastMessage(true, res.message);
                        setError([]);
                        setIsEdit(!isEdit);
                        setFormData(initialState);
                    } else {
                        setError([]);
                        toastMessage(false, res.message);
                    }
                })
                .catch((err) => {
                    // console.log(err);
                });
        }

    }

    const handleUpdate = () => {
        let updatedFormData = { ...formData };
        if (userDetail?.userType !== "superAdmin") {
            updatedFormData = {
                ...formData,
                orgId: userDetail?.orgId || "",
                orgName: userDetail?.orgName || ""
            };
        }
        // console.log("update", updatedFormData);
        const validation = validatePayload(updatedFormData);
        if (!validation.success) {
            // console.log(validation);
            setError(validation.error);
        } else {
            // console.log(formData);
            updateApprovalConfigTriggier({ ...updatedFormData, id: updatedFormData._id }).unwrap()
                .then((res) => {
                    if (!res.error) {
                        toastMessage(true, res.message);
                        setError([]);
                        setIsEdit(!isEdit);
                        setFormData(initialState);
                    } else {
                        setError([]);
                        toastMessage(false, res.message);
                    }
                })
                .catch((err) => {
                    // console.log(err);
                });
        }
    }

    const handleDelete = (id) => {
        if (!id) {
            return toastMessage(false, "Please select a row to delete");
        }
        deleteApprovalConfigTriggier({ id: id }).unwrap()
            .then((res) => {
                if (!res.error) {
                    toastMessage(true, res.message);
                } else {
                    setError([]);
                    toastMessage(false, res.message);
                }
            })
            .catch((err) => {
                // console.log(err);
            });
    }

    const handleToggleRow = (rowIndex) => {
        // console.log(rowIndex, openRows);
        setOpenRows(prevOpenRows => (prevOpenRows === rowIndex ? "" : rowIndex));
        const currentData = approvalListing[rowIndex];
        let header = [
            "S.no",
            "Order No",
            "Layer Name",
            "User Name",
            "Role",
        ];
        let rows = [];
        if (currentData.approval_layer) {
            currentData.approval_layer.map((d, i) => {
                rows.push([
                    tCell(i + 1),
                    tCell(d.order_no || '-'),
                    tCell(d.layerName || '-'),
                    tCell(d.require_user_approval ? d.user_details.name : '-'),
                    tCell(!d.require_user_approval ? d.role : '-'),
                ])
            })
        }
        setOpenRowsData({
            header: header,
            rows: rows
        })
    };


    ////table ////

    let actionHandlers = {};
    actionHandlers.edit = (index) => {
        setIsUpdating(true);
        setIsEdit(!isEdit);
        if (approvalListing.length > 0) {
            setFormData(JSON.parse(JSON.stringify(approvalListing[index])));
        }
    };
    actionHandlers.delete = (index) => {
        // console.log('deleting', index);
        if (approvalListing.length > 0) {
            const listing = (JSON.parse(JSON.stringify(approvalListing[index])));
            handleDelete(listing._id);
        }
    };
    let header = [
        "S.no",
        "Module",
        "Org Name",
        "Action",
    ];

    const rows = fetchRow(approvalListing, filter, ActionMenu, actionHandlers, handleToggleRow)

    const onPageChange = (page_no) => {
        let filterRef = { ...filter, page_no: page_no + 1 };
        setFilter(filterRef);
    };
    const onPageSizeChange = (e) => {
        const { value } = e.target;
        let filterRef = { ...filter, pagesize: value };
        setFilter(filterRef);
    };


    useEffect(() => {
        if (userDetail?.userType !== "superAdmin" && userDetail?.orgId && userDetail?.orgName) {
            userFilterRef.current = {
                orgId: userDetail?.orgId || "",
            };
            getRoles(userFilterRef.current);
            getUsers(userFilterRef.current);
        }
    }, [userDetail?.userType, userDetail?.orgId, userDetail?.orgName]);

    return (
        <div className="contentpanel">
            <div className="cust-row flex-algn-cent">
                <div className="cust-col-5">
                    <h1 className="title">{isEdit && !isUpdating ? 'Add Approval Configuration' : isUpdating ? 'Edit Approval Configuration' : 'Approval Configuration'}</h1>
                </div>
                {true ? (
                    <div className="cust-col-5 flex-jc-end">
                        <Button
                            onClick={() => handleEdit()}
                            text={isEdit ? "Go Back" : "Add Approval Configuration"}
                            style={{ ...buttonClasses.lynkitOrangeEmpty, width: 'fit-content' }}
                        />
                    </div>
                ) : null}
            </div>
            <hr className="bgg" />
            {isEdit ?
                <>
                    <Grid container spacing="1rem" mt={1}>
                        {userDetail?.userType === "superAdmin" && <Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
                            <Dropdown
                                id={"orgId"}
                                name={"orgId"}
                                value={findOrgValue(formData?.orgId, orgOptions)}
                                options={orgOptions}
                                label={"Select Organisation"}
                                onChange={(option, key) => {
                                    handleChange(option ? option : "", 'orgId')
                                }}
                                error={error?.orgId || ""}
                                readonly={isUpdating}
                            />
                        </Grid>}
                        <Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
                            <Input
                                id={"Module"}
                                name={"module"}
                                value={formData?.module}
                                label={"Module"}
                                onChange={(e) => handleChange(e.target.value, e.target.name)}
                                error={error?.module || ""}
                                sx={{ width: "100%" }}
                                readOnly={isUpdating}
                            />
                        </Grid>
                        <Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
                            <Input
                                id={"last_step_sequence"}
                                name={"last_step_sequence"}
                                value={formData?.last_step_sequence}
                                type={"number"}
                                label={"Last step sequence"}
                                onChange={(e) => handleChange(e.target.value, e.target.name)}
                                error={error?.last_step_sequence || ""}
                                sx={{ width: "100%" }}
                                readOnly={isUpdating}
                            />
                        </Grid>
                        <Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
                            <Dropdown
                                id={"templateId"}
                                name={"templateId"}
                                value={
                                    formData?.templateId && templateList && templateList?.data && templateList?.data?.length > 0
                                        ? templateList?.data?.filter((ele) => ele._id === formData?.templateId).map((ele) => ({ label: ele.name, value: ele._id }))[0]
                                        : null
                                }
                                options={templateList && templateList?.data && templateList?.data?.length > 0 ? templateList?.data?.map((ele) => ({ label: ele.name, value: ele._id })) : []}
                                label={"Approval Template Id"}
                                onChange={(option, key) => handleChange(option?.value ? option?.value : "", key)}
                            // required
                            // error={error?.checkPoint?.templateId}
                            />
                        </Grid>


                        <Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
                            <Dropdown
                                id={"rejectTemplateId"}
                                name={"rejectTemplateId"}
                                value={
                                    formData?.rejectTemplateId && templateList && templateList?.data && templateList?.data?.length > 0
                                        ? templateList?.data?.filter((ele) => ele._id === formData?.rejectTemplateId).map((ele) => ({ label: ele.name, value: ele._id }))[0]
                                        : null
                                }
                                options={templateList && templateList?.data && templateList?.data?.length > 0 ? templateList?.data?.map((ele) => ({ label: ele.name, value: ele._id })) : []}
                                label={"Reject Template Id"}
                                onChange={(option, key) => handleChange(option?.value ? option?.value : "", key)}
                            // required
                            // error={error?.checkPoint?.templateId}
                            />
                        </Grid>
                    </Grid>
                    {formData?.approval_layer && formData?.approval_layer?.length > 0 && formData?.approval_layer.map((ele, index) => (
                        <div className='approval-config-container' key={index}>
                            <div className='right-panel'>
                                <div className='right-panel-layer'>
                                    <Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
                                        <Input
                                            id={"Order No"}
                                            name={"order_no"}
                                            value={ele.order_no}
                                            type={"number"}
                                            label={"Order No."}
                                            onChange={(e) => handleChange(e.target.value, e.target.name, index)}
                                            error={error?.approval_layer?.length > 0 && error?.approval_layer[index] && error?.approval_layer[index]?.order_no || ""}
                                            sx={{ width: "100%" }}
                                            readOnly={isUpdating}
                                        />
                                    </Grid>
                                    <Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
                                        <Input
                                            id={"layerName"}
                                            name={"layerName"}
                                            value={ele.layerName}
                                            type={"text"}
                                            label={"Layer Name"}
                                            onChange={(e) => handleChange(e.target.value, e.target.name, index)}
                                            error={error?.approval_layer?.length > 0 && error?.approval_layer[index] && error?.approval_layer[index]?.layerName || ""}
                                            sx={{ width: "100%" }}
                                            readOnly={isUpdating}
                                        />
                                    </Grid>
                                </div>
                                <div className='right-panel-layer'>
                                    <Grid item xl={2} lg={2} md={3} sm={3} xs={6}>
                                        <Checkbox
                                            id={'require_user_approval'}
                                            name={"require_user_approval"}
                                            checked={ele.require_user_approval || false}
                                            onChange={(e) => handleChange(e.target.checked, e.target.name, index)}
                                            label={"User Approval Required"}
                                            disabled={isUpdating}
                                        />
                                    </Grid>
                                    {ele?.require_user_approval ? <div style={{ width: "215px" }}>
                                        <Dropdown
                                            id={"checkpoint"}
                                            name={"user_details"}
                                            value={findUserValue(ele.user_details, userOptions)}
                                            options={userOptions}
                                            label={"User"}
                                            multiple={false}
                                            readonly={isUpdating}
                                            onChange={(option, key) => handleChange(option, key, index)}
                                            error={error?.approval_layer?.length > 0 && error?.approval_layer[index] && error?.approval_layer[index].user_details ? error.approval_layer[index].user_details : ""}
                                        />
                                    </div>
                                        :
                                        <div style={{ width: "215px" }}>
                                            <Dropdown
                                                id={"role"}
                                                name={"role"}
                                                value={findRoleValue(ele.role_id, roleOptions)}
                                                options={roleOptions}
                                                label={"Role"}
                                                multiple={false}
                                                readonly={isUpdating}
                                                onChange={(option, key) => handleChange(option, key, index)}
                                                error={error?.approval_layer?.length > 0 && error?.approval_layer[index] && error?.approval_layer[index].role ? error.approval_layer[index].role : ""}
                                            />
                                        </div>}

                                </div>
                                <Grid item xl={2} lg={2} md={3} sm={3} xs={6}>
                                    <Checkbox
                                        id={'approval_require'}
                                        name={"approval_require"}
                                        checked={ele.approval_require || false}
                                        onChange={(e) => handleChange(e.target.checked, e.target.name, index)}
                                        label={"Approval Required"}
                                        disabled={isUpdating}
                                    />
                                </Grid>
                            </div>
                            {!isUpdating && <Grid item xl={1} lg={1} md={1} sm={2} xs={6} sx={{ display: "flex", gap: "0.25rem", alignItems: "center" }}>
                                <AddRemove list={formData?.approval_layer} filterMethod={(d) => d.order_no == ""} index={index} outerIndex={index} onAdd={addApprovalLayer} onRemove={removeApprovalLayer} />
                            </Grid>}
                        </div>
                    ))}
                    <div className="d-flex justify-content-center my-4">
                        <Button
                            testId={"Submit"}
                            text={isUpdating ? "Update" : "Submit"}
                            style={{ ...buttonClasses.lynkitOrangeFill, width: "10%" }}
                            onClick={() => isUpdating ? handleUpdate() : handleSubmit()}
                        />
                    </div>
                </>
                :
                <>
                    <div className="cust-row flex-algn-cent">
                        <Genericfilter filterFields={filterFields} onFilterChange={debounce((filter) => handleFilterChange(filter), 500)} />
                    </div>
                    <div>
                        <GenericTable
                            header={header}
                            rows={rows}
                            pageCount={count}
                            pageNo={filter.page_no}
                            limit={filter.pagesize}
                            onPageChange={onPageChange}
                            onPageSizeChange={onPageSizeChange}
                            openRows={openRows}
                            openRowsData={openRowsData}
                            isLoading={listingLoading}
                        />
                    </div>
                </>}
        </div>
    )
}


const findUserValue = (userDetails, options) => {
    const result = options.filter((ele) => ele.value === userDetails.id);
    if (result.length > 0) {
        const userValue = result[0];
        // console.log("userValue", options, userValue)
        return userValue;
    } else {
        return [];
    }
}

const findRoleValue = (userRoleId, options) => {
    const result = options.filter((ele) => ele.data?._id === userRoleId)
    // const result = userRoleId != "" ? options.filter((ele) => ele.data?._id === userRoleId) : options.filter((ele) => ele.value === userRoleId);
    if (result.length > 0) {
        const userValue = result[0];
        // console.log("userValue", options, userValue)
        return userValue;
    } else {
        return [];
    }
};

const findOrgValue = (orgId, options) => {
    const result = options.filter((ele) => ele.value === orgId);
    if (result.length > 0) {
        const userValue = result[0];
        // console.log("userValue", options, userValue)
        return userValue;
    } else {
        return [];
    }
};

const validatePayload = (payload) => {
    let success = true; // Initialize success to true
    let error = {};

    if (!payload.orgId) {
        error.orgId = "This field is required";
        success = false; // Set success to false if there's an error
    }
    if (!payload.orgName) {
        error.orgName = "This field is required";
        success = false; // Set success to false if there's an error
    }
    if (!payload.module) {
        error.module = "This field is required";
        success = false; // Set success to false if there's an error
    }
    if (payload.last_step_sequence === undefined || payload.last_step_sequence === "") {
        error.last_step_sequence = "This field is required";
        success = false; // Set success to false if there's an error
    }

    error.approval_layer = []; // Initialize approval_layer array outside the loop

    payload.approval_layer.forEach((layer, index) => {
        error.approval_layer[index] = {};
        if (!layer.layerName) {
            error.approval_layer[index].layerName = "This field is required";
            success = false; // Set success to false if there's an error
        }
        if (!layer.order_no) {
            error.approval_layer[index].order_no = "This field is required";
            success = false; // Set success to false if there's an error
        }
        if (!layer.require_user_approval && (!layer.role || !layer.role_id)) {
            error.approval_layer[index].role = "This field is required";
            success = false; // Set success to false if there's an error
        }
        if (layer.require_user_approval) {
            if (!layer.user_details || !layer.user_details.name) {
                error.approval_layer[index].user_details = error.approval_layer[index].user_details || {};
                error.approval_layer[index].user_details = "This field is required";
                success = false; // Set success to false if there's an error
            }
            if (!layer.user_details || !layer.user_details.email) {
                error.approval_layer[index].user_details = error.approval_layer[index].user_details || {};
                error.approval_layer[index].user_details = "This field is required";
                success = false; // Set success to false if there's an error
            }
            if (!layer.user_details || !layer.user_details.id) {
                error.approval_layer[index].user_details = error.approval_layer[index].user_details || {};
                error.approval_layer[index].user_details = "This field is required";
                success = false; // Set success to false if there's an error
            }
        }
    });

    // console.log("error", error)

    return { error, success };
};

const fetchRow = (approvalListing, filter, ActionMenu, actionHandlers, handleToggleRow) => {
    const { page_no = 1, pagesize = 10 } = filter;
    return approvalListing?.map((d, i) => {
        let row = [];
        row.push(
            tCell((page_no - 1) * pagesize + i + 1),
            tCell(<div onClick={() => handleToggleRow(i)} style={{ color: 'var(--primary-color)', cursor: 'pointer' }}>
                {d.module || "-"}
            </div>),
            tCell(d.orgName)
        );
        row.push(<div style={{ paddingLeft: "0.5rem" }}>
            <ActionMenu id={i} handlers={actionHandlers} />
        </div>);

        return row;
    }) || [];
}
