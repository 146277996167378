import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { expiredTokenRedirect, decryptUsingAES256, encryptUsingAES256 } from "../services/functions";
import { config } from "./config";

const baseQuery = fetchBaseQuery({
	baseUrl: config.baseUrl,
	prepareHeaders: (headers) => {
		const token = localStorage.getItem("secretkey");
		headers.set("authorization", `${token}`); //Bearer ${token}
		headers.set("project", localStorage.getItem("project") ? localStorage.getItem("project") : "LYNKID");
		return headers;
	},
});

const baseQueryMiddleWare = async (args, api, extraOptions) => {
	const { body, query } = args;
	// if(body) console.log({ req_body: body });
	// if (query) console.log({ req_query: query });
	let body_encrypted;
	if (["login", "forgotPassword", "updatePassword", "changePassword"].includes(api.endpoint)) {
		body_encrypted = { encStr: encryptUsingAES256(body) };
	} else {
		body_encrypted = body;
	}
	// console.log({body_encrypted})
	// const query_encrypted = encryptUsingAES256(query);
	// const query_encrypted = query;
	// console.log('encrypt', query_encrypted)
	let newArgs = { ...args };
	if (body) {
		newArgs.body = body_encrypted;
	}
	// if (query) {
	// delete newArgs.query;
	// newArgs.url = `${newArgs.url}?encStr=${query_encrypted}&isEnc=true`;
	// }
	// console.log("api", api.endpoint);
	const noPermEndpoints = ["login", "forgotPassword", "updatePassword"];
	if (!localStorage.getItem("secretkey") && !noPermEndpoints.includes(api.endpoint)) {
		// console.log("no token", { api: api.endpoint });
		return { error: "Not Authorized" };
	}
	const result = await baseQuery(newArgs, api, extraOptions);
	const { data } = result;
	const { encStr } = data;

	// const { statusCode, message } = data || {};
	// if (statusCode == 501 || message == "Session Expired. Please login again.") {
	// 	expiredTokenRedirect();
	// } else {
	// 	return result;
	// }

	if (encStr) {
		// console.log(" =============== emnc =========== ", JSON.parse(decryptUsingAES256(encStr)));
		const resp = {
			data: JSON.parse(decryptUsingAES256(encStr)),
		};
		if (resp.data.statusCode == 501 || resp.data.message == "Session Expired. Please login again.") {
			expiredTokenRedirect();
		}
		// console.log("resp", resp);
		return resp;
	} else {
		if (data.message === "Session Expired. Please login again.") {
			expiredTokenRedirect();
		}
		return result;
	}
};
const tagTypes = ["user", "org", "device", "tag", "productType", "productCategory", "supplier", "vehicle"];

export const apiSlice = createApi({
	reducerPath: "api",
	baseQuery: baseQueryMiddleWare,
	endpoints: (build) => ({
		getReportFields: build.mutation({
			query: (body) => {
				return {
					url: "vms/common/field-mapping-list",
					method: "post",
					body,
				};
			},
		}),
		getReport: build.mutation({
			query: (body) => {
				return {
					url: "/vms/common/report-generator",
					method: "post",
					body,
				};
			},
		}),
		uploadExcel: build.mutation({
			query: (body) => {
				return {
					url: "",
					method: "post",
					body,
				};
			},
		}),
		getStats: build.mutation({
			query: (body) => {
				return {
					url: "/vms/vehicle/get-checkpoint-stats",
					method: "post",
					body,
				};
			},
			providesTags: ["stats"],
		}),

		getAnalyticsStats: build.mutation({
			query: (body) => {
				return {
					url: "/vms/analytics/trips/daterange-compare",
					method: "post",
					body,
				};
			},
		}),
		updateManageTrip: build.mutation({
			query: (body) => {
				return {
					url: "/vms/vehicle/manage-trip",
					method: "post",
					body,
				};
			},
			// providesTags: ["stats"],
		}),
		modifyTrip: build.mutation({
			query: (body) => {
				return {
					url: "/vms/vehicle/modify-trip",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["stats"],
		}),
		getVMSStats: build.query({
			query: (body) => {
				return {
					url: "/vms/vehicle/get-allvms-stats",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["vmsstats"],
		}),
		getUsersStats: build.query({
			query: (body) => {
				return {
					url: "/users/get-alluser-stats",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["vmsstats"],
		}),
		getDeviceStats: build.query({
			query: (body) => {
				return {
					url: "/devices/get-alldevice-stats",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["vmsstats"],
		}),
	}),
	tagTypes,
});

export const {
	useModifyTripMutation,
	useGetDeviceStatsQuery,
	useGetAnalyticsStatsMutation,
	useGetUsersStatsQuery,
	useGetVMSStatsQuery,
	useUpdateManageTripMutation,
	useGetReportFieldsMutation,
	useGetReportMutation,
	useUploadExcelMutation,
	useGetStatsMutation,
} = apiSlice;
