import React, { useState, useRef, useEffect } from "react";
import CreateCheckpoint from "./CreateCheckpoint";
import "./CheckpointIndex.css";
import { Button, ActionMenu, GenericTable, tCell, toastMessage, Dialog, Checkbox } from "../utils";
import theme, { buttonClasses } from "../utils/theme";
import Genericfilter from "../utils/Genericfilter";
import { useCheckpoints, useOrgs, useUserDetail } from "../../services/hooks";
import { debounce, capitalizeCamelCase } from "../../services/functions";
import { GenericstatsV2 } from "../utils/Genericstats";
import { Box, Typography } from "@mui/material";
import { useUpdateUserPermissionMutation } from "../../api/userSlice";

const defaultFilter = {
	pagesize: 10,
	page_no: 1,
};

export default function CheckpointIndex() {
	const { userDetail } = useUserDetail();
	const { orgOptions } = useOrgs();

	let filterFields = [
		{
			inputType: "textfield",
			id: "1",
			name: "displayName",
			type: "text",
			label: "Display Name",
			placeholder: "Display Name",
			readOnly: false,
			error: "",
			value: "",
		},
	];
	if (userDetail?.userType == "superAdmin") {
		filterFields = [
			{
				inputType: "dropdown",
				id: "orgId",
				name: "orgId",
				label: "Organization",
				option: orgOptions,
			},
		];
	}

	const [addCheckPoint, setAddCheckPoint] = useState(false);
    const [fetchStats, setFetchStats] = useState(false);

    const [isModal,setIsModal] = useState(false);
    const [data, setData] = useState({});

	const [updatedData, setUpdatedData] = useState({});

	const isDuplicateRef = useRef(false);

	const filterRef = useRef(defaultFilter);
	const { page_no = 1, pagesize = 10 } = filterRef.current;

	const { checkpoints, count, isLoading, getCheckpoints } = useCheckpoints(filterRef.current);

	const handleDeviceView = (e, index) => {
		const { latitude, longitude } = checkpoints[index];
		if (latitude != null && longitude != null && latitude !== undefined && longitude !== undefined) {
			// Redirect to Google Maps with the specified latitude and longitude
			window.open(`https://www.google.com/maps?q=${latitude},${longitude}`, "_blank");
		} else {
			toastMessage(false, "Location is not assigned to this checkpoint.");
		}
	};

	let actionHandlers = {};
	actionHandlers.edit = (id) => {
		setUpdatedData(checkpoints[id]);
		setAddCheckPoint(!addCheckPoint);
	};
	actionHandlers.duplicate = (index) => {
		setUpdatedData(checkpoints[index]);
		setAddCheckPoint(!addCheckPoint);
		isDuplicateRef.current = true;
	};

	let header = ["S.no", "Checkpoint Number", "Display Name", "Organization", "Checkpoint Type", "Send Approval", "Dependent Approval", "Action"];

	const rows =
		checkpoints?.map((_, i) => {
			let row = [];
			row.push(
				tCell((page_no - 1) * pagesize + i + 1),
				tCell(_.checkpointNumber),
				tCell(_.displayName, theme.themeOrange, "pointer", handleDeviceView, i),
				tCell(_.orgName),
				tCell(_.checkpointType),
				tCell(_.sendApproval ? "Yes" : "No"),
				tCell(_.dependentApproval ? "Yes" : "No")
			);
			row.push(<ActionMenu id={i} handlers={actionHandlers} />);
			return row;
		}) || [];

	const onPageChange = (page_no) => {
		filterRef.current = { ...filterRef.current, page_no: page_no + 1 };
		getCheckpoints(filterRef.current);
	};
	const onPageSizeChange = (e) => {
		const { value } = e.target;
		filterRef.current = { ...filterRef.current, pagesize: value };
		getCheckpoints(filterRef.current);
	};

	const handleFilterChange = (filter) => {
		if (Object.keys(filter).length == 0) {
			return;
		}
		const allowed = ["displayName"];
		let clearFlag = true;
		if (filter?.orgId?.value) {
			filterRef.current = { ...filterRef.current, orgId: filter.orgId.value };
			clearFlag = false;
		} else {
			for (let key in filter) {
				if (allowed.includes(key) && filter[key] !== "") {
					clearFlag = false;
					filterRef.current = { ...filterRef.current, searchBy: key, search: filter[key] };
				}
			}
		}
		if (clearFlag) {
			let { page_no, pagesize } = filterRef.current;
			filterRef.current = { pagesize, page_no };
		}
		getCheckpoints(filterRef.current);
		return;
	};

	return (
        <>
            <div className="checkpoint_panel">
                <div className="checkpoint_heading">{addCheckPoint && Object.keys(updatedData)?.length > 0 ? "Update Checkpoint" : addCheckPoint ? "Add New Checkpoint" : "Checkpoint"}</div>
                <div>
                    <Button
                        onClick={() => {
                            setAddCheckPoint(!addCheckPoint);
                            setUpdatedData({});
                            if (addCheckPoint) {
                                isDuplicateRef.current = false;
                            }
                        }}
                        text={addCheckPoint ? "Go Back" : "Add Checkpoint"}
                        style={buttonClasses.lynkitOrangeEmpty}
                    />
                </div>
            </div>
            {addCheckPoint ? (
                <CreateCheckpoint
                    updatedData={updatedData}
                    isDuplicate={isDuplicateRef.current}
                    addCheckPoint={addCheckPoint}
                    setAddCheckPoint={setAddCheckPoint}
                    reset={() => {
                        isDuplicateRef.current = false;
                        getCheckpoints(filterRef.current);
                    }}
                    setData={setData}
                    setAssignUserModal={setIsModal}
                    setFetchStats={setFetchStats}
                />
            ) : (
                <>
                    <GenericstatsV2 statsFor={"checkpoint"} fetchStats={fetchStats}/>
                    <div className="cust-row flex-algn-cent">
                        <Genericfilter filterFields={filterFields} onFilterChange={debounce((filter) => handleFilterChange(filter), 500)} />
                    </div>
                    <div>
                        <GenericTable header={header} rows={rows} pageCount={count} pageNo={page_no} limit={pagesize} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} isLoading={isLoading} />
                    </div>
                </>
            )}
            {isModal ? <AssignToUsers onClose={() => setIsModal(false)} open={isModal} users={data.users} checkpoint={data.createdCheckpoint} /> : null}
        </>
    );
}

function AssignToUsers({ onClose, open, users, checkpoint }) {
    if (!users || !users.length || !checkpoint) {
        // console.log("No users / Nothing to quick assign");
        return;
    }
    const [selectedUsers,setSelectedUsers] = useState([]);
    const [permissions, setPermissions] = useState({});
    const [updateUserPermissionTrigger] = useUpdateUserPermissionMutation();

	const handleFormSubmit = async () => {
		const payload = { userId: [...selectedUsers], permissions: { ...permissions }, module: "vms", project: "lynkid", mergeWithExisting: true };
        // console.log(" THIS IS THE PAYLOAD --> ", payload);

        updateUserPermissionTrigger(payload) 
            .unwrap()
            .then((res) => {
                if (!res.error) {
                    toastMessage(true, res.message);
                    onClose();
                    setSelectedUsers([])
                    setPermissions({});
                } else {
                    toastMessage(false, res.message);
                }
            })
            .catch();
	};

    const handleCheckboxChange = (id,checked) => {
        let selected = selectedUsers.filter((users) => users != id);
        if (checked) {
            selected.push(id);
        }
        setSelectedUsers(selected);
    }

	const handleClose = () => {
		onClose();
	};

    const handleCheckpointPermChange = (e,id) => {
        const { name, checked } = e.target;
        // console.log(name,checked,id)
        setPermissions(old => {
            let update = JSON.parse(JSON.stringify(old));
            if (checked) {
                update[name] = checkpointsPerms[checkpoint._id][name];
            } else {
                delete update[name];
            }
            return update;
        })
    }

    let checkpointsPerms = makeCheckpointPerms([checkpoint]);
    let id = checkpoint._id;
    let perms = [];
    for (let key in checkpointsPerms[id]) {
        if (key != "key" && key != "permObj" && key.split("-").length == 3) {
            perms.push(
                <Checkbox
                    key={key}
                    label={capitalizeCamelCase(key.split("-")[2])}
                    id={key}
                    name={key}
                    checked={permissions[key]}
                    onChange={(e) => {
                        handleCheckpointPermChange(e, id);
                    }}
                />
            );
        }
    }
    useEffect(() => {
        let newPerms = checkpointsPerms[id];
        delete newPerms["key"]
        delete newPerms["permObj"];
        setPermissions(newPerms);
    }, []);
    
	return (
        <Dialog size="xs" open={open} handleClose={handleClose} handleSubmit={handleFormSubmit} title={`Assign ${checkpoint.displayName} to users`} isSubmitDisabled={false}>
            <Box component="form">
                <Box component="fieldset" sx={{ border: "1px solid var(--input-border-color)", margin: "0", padding: "revert", display: "grid", gridTemplateColumns: "1fr" }}>
                    <Typography component="legend" sx={{ fontSize: "1rem", p:"0 0.25rem", float: "none", width: "revert" }}>
                        Permissions
                    </Typography>
                    {perms}
                </Box>

                <Box component="fieldset" sx={{ border: "1px solid var(--input-border-color)", margin: "1rem 0 0 0", padding: "revert", display: "grid", gridTemplateColumns: "1fr" }}>
                    <Typography component="legend" sx={{ fontSize: "1rem", p:"0 0.25rem", float: "none", width: "revert" }}>
                        Users
                    </Typography>
                    {users.map((user, i) => {
                        return (
                            <Checkbox
                                id={i}
                                name={user._id}
                                label={
                                    <span>
                                        {user.name}{<span className="mini-label"> ({user.email})</span>}
                                    </span>
                                }
                                checked={selectedUsers.includes(user._id)}
                                onChange={(e) => handleCheckboxChange(e.target.name, e.target.checked)}
                            />
                        );
                    })}
                </Box>
            </Box>
        </Dialog>
    );
}

const permObj = { dependancy: [], strict: false, value: true };
function makeCheckpointPerms(checkpoints = []) {
	return checkpoints.reduce((res, curr) => {
		const key = `checkpoint-${curr._id}`;
		const listConfigPerms = curr.listingConfig?.reduce((res, curr) => {
			res[`${key}-${curr.type}`] = permObj;
			return res;
		}, {});
		let additionalPerms = {};
		if (curr.assetConfig && Object.keys(curr.assetConfig).length) {
			for (let permType in curr.assetConfig) {
				//check if the particular permission is enabled at the current checkpoint
				if (curr.assetConfig[permType]) {
					additionalPerms[`${key}-${permType}`] = { ...permObj, dependancy: [permType], strict: true };
				}
			}
		}
		res[curr._id] = {
			[key]: permObj,
			key,
			permObj,
			[`${key}-manualEntry`]: permObj,
			[`${key}-viewSubmit`]: permObj,
			[`${key}-viewDetail`]: permObj,
			[`${key}-viewListing`]: permObj,
			...(listConfigPerms || {}),
			...additionalPerms,
		};
		// console.log(additionalPerms)
		return res;
	}, {});
}