import React, { useState, useRef, useEffect } from "react";
import "./Geofence.css";
import { Input } from ".";
// import { width } from "@amcharts/amcharts4/.internal/core/utils/Utils";
const Geofence = ({ onCoordinatesChange, factoryCoordinates: initialCoordinates = [] }) => {
	const [map, setMap] = useState(null);
	const [drawingManager, setDrawingManager] = useState(null);
	const [factoryCoordinates, setFactoryCoordinates] = useState([]);
	const [location, setLocation] = useState("");
	const mapRef = useRef(null);
	const autocompleteRef = useRef(null);
	const currentPolygonRef = useRef(null); // Ref to store the current polygon
	const markerRef = useRef(null); // Ref for the marker


	const initMap = () => {
		const google = window.google;
		const mapInstance = new google.maps.Map(mapRef.current, {
			center: { lat: 28.524782, lng: 77.277073 }, // Default center
			zoom: 12,
			scrollwheel: false,
		});

		// Set up the autocomplete service on the input box
		autocompleteRef.current = new google.maps.places.Autocomplete(document.getElementById("location-input"), {
			types: ["geocode", "establishment"], // You can adjust this if you want more than just geocodes
		});

		autocompleteRef.current.setFields(["place_id", "geometry", "name"]);

		// Listen for place selection
		autocompleteRef.current.addListener("place_changed", () => {
			const place = autocompleteRef.current.getPlace();
			if (place && place.geometry) {
				// Set the map center to the selected place's location
				mapInstance.setCenter(place.geometry.location);
				mapInstance.setZoom(15); // Adjust zoom level as needed

				// Add or move marker to the selected location
				if (markerRef.current) {
					markerRef.current.setMap(null); // Remove previous marker
				}

				const marker = new google.maps.Marker({
					position: place.geometry.location,
					map: mapInstance,
					title: place.name,
				});

				markerRef.current = marker; // Store the new marker

				const coordinates = {
					lat: place.geometry.location.lat(),
					lng: place.geometry.location.lng(),
				};
				setLocation(place.formatted_address); // Update location state with formatted address
			}
		});

		// Set up DrawingManager
		const drawingManagerInstance = new google.maps.drawing.DrawingManager({
			drawingMode: google.maps.drawing.OverlayType.POLYGON, // Default drawing mode
			drawingControl: true,
			drawingControlOptions: {
				position: google.maps.ControlPosition.TOP_CENTER,
				drawingModes: [google.maps.drawing.OverlayType.POLYGON],
			},
			polygonOptions: {
				editable: true,
				draggable: true,
			},
		});

		drawingManagerInstance.setMap(mapInstance);
		setMap(mapInstance);
		setDrawingManager(drawingManagerInstance);

		// If initial coordinates are provided, create the polygon and allow editing
		if (initialCoordinates.length > 0) {
			setFactoryCoordinates(initialCoordinates);
			const polygon = new google.maps.Polygon({
				paths: initialCoordinates.map((coord) => ({ lat: coord.lat, lng: coord.lng })),
				editable: true,
				draggable: true,
				map: mapInstance,
			});

			currentPolygonRef.current = polygon;

			// After the polygon is created, make it editable using the drawing manager
			drawingManagerInstance.setDrawingMode(null); // Turn off drawing mode
			drawingManagerInstance.setMap(mapInstance);

			// Add event listeners for editing the polygon
			google.maps.event.addListener(polygon.getPath(), "set_at", updateCoordinates);
			google.maps.event.addListener(polygon.getPath(), "insert_at", updateCoordinates);

			const bounds = new google.maps.LatLngBounds();
			polygon.getPath().forEach((latLng) => {
				bounds.extend(latLng);
			});
			mapInstance.fitBounds(bounds); // Fit the map to the polygon bounds

			// Update coordinates when polygon is drawn or edited
			function updateCoordinates() {
				const coordinates = polygon
					.getPath()
					.getArray()
					.map((latLng) => ({
						lat: latLng.lat(),
						lng: latLng.lng(),
					}));
				setFactoryCoordinates(coordinates); // Update state
				onCoordinatesChange(coordinates); // Pass coordinates to parent component
			}
		}

		// Listen for polygon completion for new polygons
		google.maps.event.addListener(drawingManagerInstance, "overlaycomplete", (event) => {
			if (event.type === "polygon") {
				// Remove the previous polygon if any
				if (currentPolygonRef.current) {
					currentPolygonRef.current.setMap(null);
				}

				// Set the new polygon
				const polygon = event.overlay;
				currentPolygonRef.current = polygon;

				// Add event listeners for editing the polygon
				google.maps.event.addListener(polygon.getPath(), "set_at", updateCoordinates);
				google.maps.event.addListener(polygon.getPath(), "insert_at", updateCoordinates);

				// Initialize coordinates when the polygon is first created
				updateCoordinates();

				// Update coordinates when polygon is drawn or edited
				function updateCoordinates() {
					const coordinates = polygon
						.getPath()
						.getArray()
						.map((latLng) => ({
							lat: latLng.lat(),
							lng: latLng.lng(),
						}));
					setFactoryCoordinates(coordinates); // Update state
					onCoordinatesChange(coordinates); // Pass coordinates to parent component
				}
			}
		});
	};

	const clearGeofence = () => {
		// Remove the polygon from the map but keep the location marker
		if (currentPolygonRef.current) {
			currentPolygonRef.current.setMap(null);
			currentPolygonRef.current = null; // Reset the polygon reference
		}

		setFactoryCoordinates([]);
	};

	// Load Google Maps script
	useEffect(() => {
		const script = document.createElement("script");
		script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAUZmgZLr5piXnOt0ibo1ypiiBfjJ_isiY&libraries=drawing,places`;
		script.async = true;
		script.onload = initMap;
		document.head.appendChild(script);

		return () => {
			document.head.removeChild(script);
		};
	}, []);

	return (
		<div className="geofence-container">
			<div className="location-search" >
				<Input 
					id="location-input" 
					label="Search location" 
					type="text" 
					value={location} 
					onChange={(e) => setLocation(e.target.value)} 
					sx={{ width: '100%', margin:'8px 0px' }} 
				/>

			</div>
			<div style={{ position: "relative" }}>
				<div ref={mapRef} className="map" style={{ height: "500px", width: "100%" }}></div>
				<div className="controls">
					<button type="button" onClick={clearGeofence} className="clear-button">
						Clear Geofence
					</button>
				</div>
			</div>

			{factoryCoordinates.length > 0 && (
				<div className="coordinate_container">
					<h6>Factories Co-ordinates</h6>
					<div className="coordinate_box">
						{factoryCoordinates.map((coordinate, index) => (
							<div key={index}>
								<ul>
									<li>
										<div>Latitude:{coordinate.lat}</div>
										<div>Longitude:{coordinate.lng}</div>
									</li>
								</ul>
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export default Geofence;
