import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom/dist";
import dayjs from "dayjs";
import theme, { buttonClasses } from "../utils/theme";
import moment from "moment";
import "./NewDashboard.css";
import { useGetDashboardStatsV2Mutation } from "../../api/vmsSlice";
import { Button, GenericTable, tCell, DatePicker } from "../utils";
import { findDeepValue, removeSpecialChars, capitalizeCamelCase, formatDate } from "../../services/functions";
import Genericstats from "../utils/Genericstats";
import { useCheckpointData, useCheckpoints, useUserPermissions, useUserDetail } from "../../services/hooks";
import { useVehicleDetailsMutation } from "../../api/vmsSlice";
import AllCheckpointData from "./AllCheckpointData";
import { PopOver } from "../utils";
import NewBarChart from "./NewBarChart";
import PieChartComponent from "./NewPieChart";
import { CheckIcon } from "../../services/icons";
import { Dropdown } from "../utils";
import CompanyStats from "./CompanyStats";
import { useSocketContext } from "../../services/socketContext";

const defaultFilter = {
	pagesize: 5,
	page_no: 1,
};

export default function NewDashboard() {
	const { userPermissions, isFetched } = useUserPermissions();
	const { userDetail } = useUserDetail();

	if (isFetched && userDetail.userType == "superAdmin") {
		return <CompanyStats />;
	} else {
		return <UserDashboard />;
	}
}

function UserDashboard() {
	const { userPermissions, isFetched } = useUserPermissions();
	const navigate = useNavigate();
	const filterRef = useRef(defaultFilter);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [customDate, setCustomDate] = useState(null);
	const [barFilter, setBarFilter] = useState({ label: "Today", value: "today" });
	// const [vehicledata, setVehicleData] = useState({});
	const { page_no, pagesize } = filterRef.current;
	const { trigger, checkpointData, isLoading: isCheckpointDataLoading, tableHeader, count } = useCheckpointData(filterRef.current);
	const [vehicleDetailTrigger, { data: vehicleDetail, isLoading: vehicleDetailLoading }] = useVehicleDetailsMutation();
	const {
		socketData: { checkpointEvents },
	} = useSocketContext();
	const { checkpoints, isLoading } = useCheckpoints({});
	const firstCheckpointId = checkpoints[0]?._id;
	const lastCheckpointId = checkpoints[checkpoints.length - 1]?._id;
	const lastSecondCheckpointId = checkpoints[checkpoints.length - 2]?._id;
	const checkpointNumber = checkpoints[0]?.checkpointNumber;
	const checkpointType = checkpoints[0]?.checkpointType;

	// console.log('useCheckpoint', checkpoints[0]);
	// const [selectedBar, setSelectedBar] = useState(null);
	const [dataSet, setDataSet] = useState([]); // Add this line
	const [pieData, setPieData] = useState([]); // Add this line
	const [active, setActive] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [chartWidth, setChartWidth] = useState("100%");
	const [getDashboardStatsV2, { data, isLoading: statsLoading }] = useGetDashboardStatsV2Mutation();

	const fetchStatus = (filter) => {
		getDashboardStatsV2(filter)
			.unwrap()
			.then((res) => {
				// console.log('res', res)
				if (res?.barData) {
					setDataSet(res.barData);
				}
				if (res?.dateWiseData) {
					if (res?.dateWiseData?.length > 0) {
						setDataSet(validateHasAllDay(res?.dateWiseData));
					} else {
						// console.log('wewew', getEmptyLastSevenDaysBarData())
						setDataSet(getEmptyLastSevenDaysBarData());
					}
					// setDataSet(res?.dateWiseData);
				}
				if (res?.pieData) {
					const pieRes = transFormPieData(res?.pieData);
					// console.log("peiRes", pieRes);
					setPieData(pieRes);
				}
			})
			.catch((err) => {
				// console.log("err", err);
			});
	};

	const fetchTable = (index) => {
		// console.log('fetching table for: ', index, dataSet[index]);
		const { checkpointId, checkpointNumber } = dataSet[index];
		if (barFilter?.value === "custom") {
			if (checkpointId) {
				let date = moment(customDate, "YYYY/MM/DD");
				let startDate = date.startOf("day").toDate();
				let endDate = date.endOf("day").toDate();
				filterRef.current = {
					...filterRef.current,
					checkpointId,
					type: "arrived",
					start: startDate,
					end: endDate,
					atCheckpoint: checkpointNumber,
				};
				trigger(filterRef.current);
			}
		} else {
			if (checkpointId) {
				filterRef.current = { ...filterRef.current, checkpointId, type: "arrived", start: moment().startOf("day").toString(), end: null, atCheckpoint: checkpointNumber };
				trigger(filterRef.current);
			}
		}
	};

	const onPageChange = (page_no) => {
		filterRef.current = { ...filterRef.current, page_no: page_no + 1 };
		trigger(filterRef.current);
	};
	const onPageSizeChange = (e) => {
		const { value } = e.target;
		filterRef.current = { ...filterRef.current, pagesize: value };
		trigger(filterRef.current);
	};

	const onFilterChange = (e, _name) => {
		let name = _name ? _name : e.target.name;
		let value = e?.$d ? new Date(e.$d).toISOString() : _name ? e : e.target.value;
		// console.log('datepicker', name, value);
		if (name == "start") {
			if (value) {
				setStartDate(value);
			} else {
				setStartDate(null);
			}
		}
		if (name == "end") {
			if (value) {
				setEndDate(value);
			} else {
				setEndDate(null);
			}
		}
		if (name === "barFilter") {
			setActive(false);
			setBarFilter(value);
			if (value?.value === "today") {
				fetchStatus({ type: "all" });
			}
			if (value?.value === "weekend") {
				const { todayDate, lastSevenDate } = getLastSevenDays();
				fetchStatus({
					type: "dateWiseTrips",
					start: lastSevenDate,
					end: todayDate,
				});
			}
		}
	};

	const handlePopOver = (event, id) => {
		setAnchorEl(event.currentTarget);
		// console.log("event", id);
		vehicleDetailTrigger({ vehicleEntryId: id });
	};

	const handleClosePopOver = () => {
		setAnchorEl(null);
	};

	const openPopOver = Boolean(anchorEl);
	const popOverId = open ? "simple-popover" : undefined;

	const new_tableheader = tableHeader?.filter((item, index) => {
		if (item.label !== "Vehicle In Time" && item.label !== "Vehicle Out Time") {
			return item;
		}
	});
	let header = ["S.No", ...(new_tableheader || []).map((_) => _.label)];
	let rows =
		(checkpointData &&
			checkpointData.length > 0 &&
			[...checkpointData].map((data, index) => {
				let row = [tCell(index + 1)];
				for (let item of new_tableheader) {
					// console.log('item', item, data)
					if (item.name === "vehicleNumber") {
						row.push(tCell(findDeepValue(data, item.name.split(".")), theme.themeOrange, "pointer", handlePopOver, data._id));
					} else {
						row.push(tCell(item.type === "date" ? formatDate(findDeepValue(data, item.name.split("."))) : findDeepValue(data, item.name.split("."))));
					}

					//
				}
				return row;
			})) ||
		[];

	useEffect(() => {
		fetchStatus({ type: "all" });
	}, []);

	useEffect(() => {
		if (checkpointEvents.length > 0) {
			fetchStatus({ type: "all" });
		}
	}, [checkpointEvents]);

	useEffect(() => {
		if (startDate && endDate) {
			const newStartDate = dayjs(startDate).add(1, "day").toDate().toISOString();
			const newEndDate = dayjs(endDate).add(1, "day").toDate().toISOString();
			fetchStatus({ start: startDate, end: newEndDate, type: "pie" });
		}
	}, [startDate, endDate]);

	const handleBarClick = (d) => {
		if (d._dataContext.checkpointId) {
			setActive(true);
			fetchTable(d._index);
			setChartWidth("100%");
		}
		if (d._dataContext._id) {
			let inputDate = d._dataContext._id;
			setCustomDate(inputDate);
			let date = moment(inputDate, "YYYY/MM/DD");
			// Get the start of the day (00:00:00)
			let startDate = date.startOf("day").toDate();
			// Get the end of the day (23:59:59)
			let endDate = date.endOf("day").toDate();
			setBarFilter({ label: `${inputDate}`, value: "custom" });
			fetchStatus({
				type: "bar",
				start: startDate,
				end: endDate,
			});
		}
	};

	if (isFetched && userPermissions && Object.keys(userPermissions).length && !userPermissions.getDashboardV2?.value) return <Navigate to="/not-authorized" replace />;
	return (
		<>
			<div className="dashboard-container">
				<div className="contentpanel">
					<div className="dashboard-row cust-row flex-algn-cent">
						<div className="cust-col-5">
							<h1 className="title">Dashboard (Internal Truck Management System)</h1>
						</div>
						<div className="cust-col-5 flex-jc-end"></div>
					</div>
					<hr className="bgg" />
				</div>
				<div style={{ width: "100%", marginLeft: "4px" }}>
					<div className="cust-row flex-algn-cent">
						<Genericstats checkpointID={"all"} />
					</div>
				</div>
				<div className="chart-parent-container">
					<div className="barchart-filter-container">
						<div style={{ width: "230px" }}>
							<Dropdown
								id="filter"
								name="filter"
								label="Filter"
								onChange={(option) => onFilterChange(option, "barFilter")}
								options={[
									{ label: "Today", value: "today" },
									{ label: "Last 7 Days", value: "weekend" },
								]}
								value={barFilter}
								required
							/>
						</div>
					</div>
					<div className="table-chart-container">
						<div className={active ? "active" : "chart-container"}>
							{dataSet.length > 0 && <NewBarChart dataSet={dataSet} chartFor={barFilter?.value} chartOnClick={(d) => handleBarClick(d)} chartWidth={chartWidth} />}
						</div>
						{active ? (
							<div className="chart-detail-table-container">
								<Button
									text={"X"}
									style={{
										...buttonClasses.lynkitOrangeFill,
										height: "35px",
										minWidth: "35px",
										width: "35px",
										borderRadius: "50%",
										transform: "Translate(-12px, -3px)",
										position: "absolute",
										right: "-28px",
										top: "-20px",
									}}
									onClick={() => {
										setActive(false);
										setChartWidth("100%");
									}}
								/>
								<GenericTable
									header={header}
									rows={rows}
									// TableData={[...checkpointEvents, ...filteredCheckpointData]}
									pageCount={count}
									pageNo={page_no}
									limit={pagesize}
									IsScroll={false}
									onPageChange={onPageChange}
									onPageSizeChange={onPageSizeChange}
									isLoading={isCheckpointDataLoading}
								/>
							</div>
						) : null}
					</div>
				</div>
				<div className="pie-chart-parent-container">
					{pieData.length > 0 ? (
						<>
							<div className="pie-chart-header-container">
								<div className="pie-chart-header">
									<h5 className="mt-2">Product Wise</h5>
								</div>
								<div className="pie-chart-date-filter">
									<div className="datepicker-container" style={{ width: "180px" }}>
										{/* start, end */}
										<DatePicker name={"start"} maxDATE={dayjs()} value={startDate} required onChange={(val) => onFilterChange(val, "start")} label={"Start Date"} />
										{/* <DatePicker maxDATE={dayjs()} label="Start Date" name="start" required onChange={(val) => onFilterChange(val, "start")} /> */}
									</div>
									&nbsp;&nbsp;&nbsp;
									<div className="datepicker-container" style={{ width: "180px" }}>
										<DatePicker
											name={"start"}
											minDATE={startDate ? dayjs(startDate) : null}
											maxDATE={dayjs()}
											value={endDate}
											required
											onChange={(val) => onFilterChange(val, "end")}
											label="End Date"
										/>
										{/* <DateTimePicker minDATE={startDate ? dayjs(startDate) : null} maxDATE={dayjs()} label="End Date" name="end" required onChange={(val) => onFilterChange(val, "end")} /> */}
									</div>
								</div>
							</div>
							<div className="pie-chart-container">
								{pieData.length > 0 &&
									pieData.map((ele, i) => {
										return (
											<div className="pie-chart-container-child" key={ele.productCategoryId || i}>
												<PieChartComponent pieData={ele} />
											</div>
										);
									})}
							</div>
						</>
					) : null}

					<div className="checkpoint-table-container">
						<div className="checkpoint-table">
							<AllCheckpointData forwordLink={`/checkpoints/${checkpointType}/${checkpointNumber}`} />
						</div>
					</div>
				</div>
			</div>
			<PopOver id={popOverId} open={openPopOver} anchorEl={anchorEl} onClose={handleClosePopOver}>
				{vehicleDetailLoading ? (
					<p>loading.....</p>
				) : vehicleDetail?.data?.statusHistory ? (
					<div className="dashboard-popover-container">
						<div className="status-history">
							<span>Status History</span>
						</div>
						{vehicleDetail?.data?.statusHistory.map((ele, index) => (
							<div className="dashboard-status-card" key={ele._id}>
								<div className="dashboard-status-card-uppper">
									<div className="dashboard-status-card-uppper-left">
										<div className="dot">
											<CheckIcon fontSize={"10px"} htmlColor={"#fff"} />
										</div>
										<div className="arrow"></div>
									</div>
									<div className="dashboard-status-card-uppper-right">
										<div className="content-header">
											<span>{ele.displayName}</span>
										</div>
										<div className="content-detail">
											<span className="title">Gate-In :</span>
											<span className="detail">{formatDate(ele.inTime)}</span>
											<br />
											<span className="title">Gate-Out : </span>
											<span className="detail">{formatDate(ele.outTime)}</span>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				) : null}
			</PopOver>
		</>
	);
}

const getLastSevenDays = () => {
	// var today = new Date();
	// var lastSevenDays;
	// var date = new Date(today);
	// date.setDate(today.getDate() - 6);
	// lastSevenDays = date.toISOString();
	return {
		todayDate: moment().endOf("day"),
		lastSevenDate: moment().subtract(6, "days").startOf("day"),
	};
};

const validateHasAllDay = (rawData) => {
	var today = new Date();
	var lastSevenDays = [];
	for (var i = 0; i < 7; i++) {
		var date = new Date(today);
		date.setDate(today.getDate() - (6 - i));
		const result = rawData.find((ele) => ele._id == date.toISOString().split("T")[0]);
		if (result) {
			lastSevenDays.push(result);
		} else {
			lastSevenDays.push({ _id: date.toISOString().split("T")[0], totalIn: 0, totalOut: 0 });
		}
	}
	return lastSevenDays;
};

function getEmptyLastSevenDaysBarData() {
	var today = new Date();
	var lastSevenDays = [];
	for (var i = 0; i < 7; i++) {
		var date = new Date(today);
		date.setDate(today.getDate() - i);
		lastSevenDays.push({ _id: date.toISOString().split("T")[0], totalIn: 0, totalOut: 0 });
	}
	return lastSevenDays;
}

const transFormPieData = (data) => {
	const transformedData = [];

	data.forEach((ele) => {
		const temp = ele.products.map((product) => ({
			sector: product.product,
			size: product.total,
		}));

		const transformedCategory = {};
		transformedCategory["productCategory"] = ele.productCategory;
		transformedCategory["product"] = temp;

		transformedData.push(transformedCategory);
	});

	return transformedData;
};

export const CalculatePercentage = (data) => {
	// Calculate percentage for each product within its block
	let result = data.map((block) => {
		const blockTotal = block.products.reduce((acc, product) => acc + product.total, 0);
		const product =
			block.products.map((p) => {
				const productPercentage = (p.total / blockTotal) * 100;
				return { id: p.productId, value: p.total, label: p.product, percent: productPercentage.toFixed(2) };
			}) || [];
		return {
			productCategory: block.productCategory,
			productCategoryId: block.productCategoryId,
			data: product,
		};
	});

	return result;
};
