import { createContext, useContext, useState } from "react";

const SocketContext = createContext();

export function SocketProvider({ children }) {
	const [socketData, setSocketData] = useState({
		checkpointEvents: [],
		tagData: {},
		deviceData: {},
		assetScanData: [],
		approvalEvents: [],
	});

	const value = { socketData, setSocketData };
	return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
}

export function useSocketContext() {
	const context = useContext(SocketContext);
	if (!context) {
		throw new Error("useSocketContext must be within a TourProvider");
	}
	return context;
}
