import dayjs from "dayjs";
import { capitalizeCamelCase, parseUnderscore } from "../../services/functions";
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(localizedFormat);
export function validateFields(fields, errors) {
	let msg;
	fields.forEach((field) => {
		const { value, values, required, regex, label, _id, type, childrens, conditionalView, conditionSatisfied, isCustomValidation, customValidation, selectableTable } = field;
		if (isVisible(conditionalView, conditionSatisfied)) {
			if (type == "grid_box") {
				validateFields(childrens, errors);
			} else {
				// console.log({ type, value, selectableTable });
				if (isCustomValidation && customValidation) {
					for (let i = 0; i < customValidation.length; i++) {
						const { key, value, type, operator } = customValidation[i] || {};

						if (!key || !type || !operator) {
							// errors[_id] = `Config Error: Missing values in Custom Validation`;
							// console.log(`Config Error: Missing values in Custom Validation`);
							break;
						}

						let validationValue = value;
						const $self = getSelf(field);
						// console.log($self)
						let pathValue = key == "self" ? field.value : getPathValue($self, key.split(".").slice(1).join("."));
						// console.log({pathValue})
						if (pathValue != undefined) {
							if (type == "number") {
								validationValue = +value;
								pathValue = +pathValue;
							} else if (type == "boolean") {
								validationValue = value == "true" ? true : false;
								if (typeof pathValue != "boolean") {
									pathValue = pathValue == "true" ? true : false;
								}
							}

							let diff;
							// console.log({validationValue, pathValue})

							// for strings and booleans, we will restrict to only equality comparisons
							if (type == "string" || type == "boolean") {
								diff = pathValue == validationValue ? 0 : -1;
							} else if (type == "number") {
								diff = pathValue - validationValue;
							} else if (["date", "currentDate"].includes(type) && isValidDate(pathValue)) {
								validationValue = type == "currentDate" ? dayjs() : dayjs(validationValue);
								pathValue = dayjs(pathValue);
								// console.log({validationValue, pathValue})

								diff = pathValue.diff(validationValue);

								validationValue = validationValue.format("LLL");
								pathValue = pathValue.format("LLL");
							}

							// console.log({validationValue, pathValue})
							let { isValid, operatorMsg } = validDiff(operator, diff);

							if (isValid != null && !isValid) {
								let label = capitalizeCamelCase(parseUnderscore(key == "self" ? field._id : key.split(".").pop()));
								errors[_id] = `${label} (${pathValue === "" ? '" "' : pathValue}) should be ${operatorMsg} ${validationValue}`;
								break;
							}
						} else {
							errors[_id] = `${capitalizeCamelCase(parseUnderscore(key == "self" ? field._id : key.split(".").pop()))} is missing`;
							console.error(`Path Value not found for key: ${key}`);
							break;
						}
					}
				} else if (type == "table" && selectableTable) {
					if (!values.length) {
						msg = `${label} data not found.`;
					} else if (value == "" || (Array.isArray(value) && !value.length)) {
						msg = `Please select at least one value`;
					}
				} else if (required && isValueMissing(value)) {
					msg = `${label} is required`;
				} else if (required && isMultiValueMissing(value)) {
					msg = "Please fill the input value";
				} else if (regex && !new RegExp(regex).test(value)) {
					msg = `Invalid value for ${label}`;
				}

				if (msg) {
					errors[_id] = msg;
					msg = "";
				}
			}
		}
	});
}

function isValueMissing(value) {
	return value == undefined || (typeof value != "boolean" && value == "") || (Array.isArray(value) && !value.length);
}

// when the input field is multi input, "value" will store an array of strings, in that case throw an error if any of the string is empty
function isMultiValueMissing(value) {
	return Array.isArray(value) && value.every((v) => typeof v == "string") && !value.every(Boolean);
}
function validDiff(operator, diff) {
	switch (operator) {
		case "=":
			return { isValid: diff == 0, operatorMsg: "equal to" };
		case ">":
			return { isValid: diff > 0, operatorMsg: "greater than" };
		case ">=":
			return { isValid: diff >= 0, operatorMsg: "greater than and equal to" };
		case "<":
			return { isValid: diff < 0, operatorMsg: "less than" };
		case "<=":
			return { isValid: diff <= 0, operatorMsg: "less than and equal to" };
		default:
			console.error(`Unsupported operator : ${operator}`);
			return { isValid: null };
	}
}
function isValidDate(v) {
	return dayjs(
		v,
		["YYYY-MM-DD", "YYYY-DD-MM", "DD-MM-YYYY", "MM-DD-YYYY", "YYYY/MM/DD", "YYYY/DD/MM", "DD/MM/YYYY", "MM/DD/YYYY", "X", "x", "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DDTHH:mm:ss.SSS[Z]"],
		true
	).isValid();
}

export function isVisible(conditionalView, conditionSatisfied) {
	return !conditionalView || (conditionalView && conditionSatisfied);
}

export function getPathValue($self, key) {
	let value = $self;
	try {
		let keyArr = key.split(".");
		if (Array.isArray(value)) {
			value = $self.map((val) => getInternalValue(val, keyArr));
		} else {
			value = getInternalValue(value, keyArr);
		}

		function getInternalValue(value, keyArr) {
			for (let key of keyArr) {
				if (value && value[key] != undefined) {
					value = value[key];
				} else {
					value = undefined;
					break;
				}
			}
			return value;
		}
	} catch (e) {
		// console.log(e, key, $self);
	}

	return value === "" ? undefined : value;
}

export function getSelf(field) {
    let result = "";
    const { type, values, apiBindingValue, value } = field;
    if (type == "select" && values?.length) {
        if(!Array.isArray(value) || (Array.isArray(value) && value.length == 1)){
            result = values.find((_) => _[apiBindingValue] == value);
        } else if(Array.isArray(value)){
            result = values.filter((_) => value.includes(_[apiBindingValue]) );
        }
    } else {
        result = value;
    }
    return result;
}

export function checkForFlags(data, currentCheckpointName, userEmail) {
	if (typeof data == "object" && !Array.isArray(data)) {
		for (let key in data) {
			if (data[key] == "#checkpointName") {
				data[key] = currentCheckpointName;
			}
			if (data[key] == "#userEmail") {
				data[key] = userEmail;
			}
			if (!Array.isArray(data[key]) && typeof data[key] == "object") {
				checkForFlags(data[key], currentCheckpointName, userEmail);
			} else if (Array.isArray(data[key])) {
				data[key].forEach((d) => checkForFlags(d, currentCheckpointName, userEmail));
			}
		}
	}
}
