import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {  GenericTable,  Button, tCell, ActionMenu,generateDetailRows, DetailCard, toastMessage} from "../utils/index";
import theme, { buttonClasses } from "../utils/theme";
import {  useOrgs, useUserPermissions } from "../../services/hooks";
import { capitalizeCamelCase,  formatDate } from "../../services/functions";
import { Navigate } from "react-router-dom/dist";
import Genericfilter from "../utils/Genericfilter";
import { debounce } from "../../services/functions";
import { GenericstatsV2 } from "../utils/Genericstats";
import "./Company.css";
import { assignDeep } from "../Dynamic/DynamicForm";
import { useToggleOrgStatusMutation } from "../../api/userSlice";

const defaultFilter = {
	pagesize: 10,
	page_no: 1,
};

const filterFields = [
	{
		inputType: "textfield",
		id: "name",
		name: "name",
		type: "text",
		label: "Company Name",
		placeholder: "Company Name",
		readOnly: false,
		error: "",
		value: "",
	},
	{
		inputType: "dropdown",
		id: "status",
		name: "status",
		label: "Status",
		error: "",
		option: [
			{ label: "Active", value: "ACTIVE" },
			{ label: "Blocked", value: "Blocked" },
		],
	},
];

function getCompanyDetails(data) {
	const dataToShow = ["companyName", "representativeName", "representativeEmail", "representativeMobile", "address", "state", "city", "pincode", "pan", "gstin", "status"];
	const res = dataToShow.map((key) => ({ label: capitalizeCamelCase(key), value: data[key == "companyName" ? "name" : key] || "-" }));
	return res;
}

const Company = () => {
	const { userPermissions, isFetched } = useUserPermissions();
	const [filterApplied, setFilterApplied] = useState(false);
	const [fetchStats, setFetchStats] = useState(false);
	const updateDataRef = useRef(null);
	const filterRef = useRef(defaultFilter);
	const { page_no, pagesize } = filterRef.current;
	const { orgs, count, isLoading, message, refetch } = useOrgs(filterRef.current);
	const [isOpen, setIsOpen] = useState(false);
	const [detail, setDetail] = useState(null);
	const navigate = useNavigate();
	const [toggleOrgStatus] = useToggleOrgStatusMutation();
	const handleDetailClose = () => {
		setIsOpen(false);
		setDetail(null);
	};

	const handleDeviceView = (e, index) => {
		// console.log(index, "view", devices[index]);
		setDetail(generateDetailRows(getCompanyDetails(orgs[index])));
		setIsOpen(true);
	};

	const fetchToggleOrgStatus = async (id, isApproved) => {
		const payload = {
			ids: [orgs[id]._id],
			projectId: "LYNKID", 
			isApproved: isApproved,
			remark: "",
			toggleUsers: true
		};

		try {
			const response = await toggleOrgStatus(payload).unwrap();
			// console.log("response", response);
			refetch();
			if (response?.error) {
				toastMessage(false, response?.orgToggleResult?.message);
			} 
			else 
			{
				toastMessage(true, response?.orgToggleResult?.message);

			}
		} catch (error) {
			console.error("Error toggling organization status:", error);
		}
	};

	let actionHandlers = {};

	if (userPermissions?.updateOrganization?.value) {
	actionHandlers.edit = (id) => {
		updateDataRef.current = orgs[id];;
		navigate("/companies/edit-company", { state: { updateData: orgs[id] } });
	}
}


	let header = ["S.no", "Company Name", "Status", "Added On"];
	// if (userPermissions?.updateOrganization?.value || userPermissions?.deleteOrganization?.value) {
	// 	header.push("Action");
	// }
	header.push("Action");
	const rows =
		orgs?.map((d, i) => {
			let row = [];
			row.push(tCell((page_no - 1) * pagesize + i + 1), tCell(d.name, theme.themeOrange, "pointer", handleDeviceView, i), tCell(d.status), tCell(formatDate(d.addedOn)));
			// if (userPermissions?.updateOrganization?.value || userPermissions?.deleteOrganization?.value) {
			// 	<ActionMenu id={i} handlers={actionHandlers} />;
			// }

		let actionHandlers = {
			edit: () => navigate("/companies/edit-company", { state: { updateData: orgs[i] } }),
		};

		// Add only the relevant action
		if (d.status === "ACTIVE") {
			actionHandlers["Disable"] = () => 
				{
					fetchToggleOrgStatus(i, false);
				}
		} else {
			actionHandlers["Enable"] = () => 
				{
					fetchToggleOrgStatus(i, true);
				}
		}
			row.push(
				<div style={{ paddingLeft: "0.5rem" }}>
					<ActionMenu id={i} handlers={actionHandlers} />
				</div>
			);

			return row;
		}) || [];

	const onPageChange = (page_no) => {
		filterRef.current = { ...filterRef.current, page_no: page_no + 1 };
		refetch();
	};
	const onPageSizeChange = (e) => {
		const { value } = e.target;
		filterRef.current = { ...filterRef.current, pagesize: value };
		refetch();
	};
	const handleFilterChange = (filter) => {
		if ((filter?.name != "" || filter?.status != "") && Object.keys(filter).length > 0) {
			// console.log('filter on change-device', filter);
			// Update orgId property safely
			const updatedFilter = { ...filter };
			const statusValue = filter?.status?.value || "";
			updatedFilter.status = statusValue;

			// Update filterRef.current
			filterRef.current = { ...filterRef.current, ...updatedFilter };
			refetch();
			setFilterApplied(true);
		}
		if (filter?.name === "" && filter?.status === "" && Object.keys(filter).length > 0 && filterApplied === true) {
			// console.log('without-filter on change-device', filter);
			filterRef.current = { ...defaultFilter };
			refetch();
			setFilterApplied(false);
		}
	};
	if (isFetched && userPermissions && Object.keys(userPermissions).length && !userPermissions.getOrganizations?.value) return <Navigate to="/not-authorized" replace />;

	return (
		<div className="contentpanel">
			<div className="cust-row flex-algn-cent">
				<div className="cust-col-5">
					<h1 className="title">Company Management</h1>
				</div>
				{userPermissions?.addOrganization?.value ? (
					<div className="cust-col-5 flex-jc-end">
						<Link className="a_tag_no" to={"/companies/add-company"}>
							<Button text="Add Company" style={buttonClasses.lynkitOrangeEmpty} />
						</Link>
					</div>
				) : null}
			</div>
			<hr className="bgg" />
			<GenericstatsV2 statsFor={"company"} fetchStats={fetchStats}/>
			<div className="cust-row flex-algn-cent">
				<Genericfilter filterFields={filterFields} onFilterChange={debounce((filter) => handleFilterChange(filter), 500)} />
			</div>
			<div className="main_content">
				<GenericTable
					header={header}
					rows={rows}
					pageCount={count}
					pageNo={filterRef.current?.page_no}
					limit={filterRef.current?.pagesize}
					onPageChange={onPageChange}
					onPageSizeChange={onPageSizeChange}
					isLoading={isLoading}
				/>
				<DetailCard isOpen={isOpen} onClose={handleDetailClose} title={"Company Details"}>
					{detail}
				</DetailCard>
			</div>
		</div>
	);
};

export default Company;

const defaultForm = {
	orgName: "",
	representativeName: "",
	representativeEmail: "",
	representativeMobile: "",
	address: "",
	state: "",
	city: "",
	pincode: "",
	pan: "",
	gstin: "",
	updateId: undefined,
	title: "",
	theme: {
		primaryColor: "#ff7200",
		selectedTheme: "lightMode",
	},
	logo: {
		fileData: "",
		docType: "",
		fileName: "",
	},
	favIcon: {
		fileData: "",
		docType: "",
		fileName: "",
	},
	permissions: {},
	hhtImei: [{ imei: "" }],
	statIcon: {
		total: {
			fileData: "",
			docType: "",
			fileName: "",
		},
		todayIn: {
			fileData: "",
			docType: "",
			fileName: "",
		},
		todayOut: {
			fileData: "",
			docType: "",
			fileName: "",
		},
	},
	factoryCoordinates: [],
};

function fillUpdateData(data) {
	// console.log('data', data)
	return {
		orgName: data.name,
		representativeName: data.representativeName,
		representativeEmail: data.representativeEmail,
		representativeMobile: data.representativeMobile,
		address: data.address,
		state: data.state,
		city: data.city,
		pincode: data.pincode,
		pan: data.pan,
		gstin: data.gstin,
		updateId: data._id,
		title: data.themeSettings.title,
		theme: {
			primaryColor: data.themeSettings.theme.primaryColor,
			selectedTheme: data.themeSettings.theme.selectedTheme,
		},
		logo: {
			fileData: "",
			docType: "",
			fileName: data.themeSettings.logo,
		},
		favIcon: {
			fileData: "",
			docType: "",
			fileName: data.themeSettings.favIcon,
		},
		permissions: data.permissions || {},
		hhtImei: data.hhtImei || [{ imei: "" }],
		statIcon: {
			total: {
				fileData: "",
				docType: "",
				fileName: data.themeSettings.statIcon?.total || "",
			},
			todayIn: {
				fileData: "",
				docType: "",
				fileName: data.themeSettings.statIcon?.todayIn || "",
			},
			todayOut: {
				fileData: "",
				docType: "",
				fileName: data.themeSettings.statIcon?.todayOut || "",
			},
		},
	};
}
let orgPerms = {
	multiTagSupport: {
		value: true,
		strict: false,
		dependancy: [],
	},
	thirdPartyTripSetup: {
		value: true,
		strict: false,
		dependancy: [],
	},
	thirdPartyTripStatusUpdate: {
		value: true,
		strict: false,
		dependancy: [],
	},
	assetMappingSupport: {
		value: true,
		strict: false,
		dependancy: [],
	},
	assetAdd: {
		value: true,
		strict: false,
		dependancy: [],
	},
	assetScan: {
		value: true,
		strict: false,
		dependancy: [],
	},
	createTripsThroughStrayFastags:{
		value:true,
		strict: false,
		dependancy:[]
	}
};

const DEFAULT_CHEKPOINTS = [
	{ displayName: "Access", checkpointType: "access" },
	{ displayName: "Gate In", checkpointType: "gateIn" },
	{ displayName: "Bay", checkpointType: "bay" },
	{ displayName: "Gate Out", checkpointType: "gateOut" },
];


